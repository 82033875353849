* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0 auto;
  min-height: 100vh;
  background-color: #fff;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("./assets/fonts/SpaceGrotesk-Bold.woff2") format("woff2"),
    url("./assets/fonts/SpaceGrotesk-Bold.otf") format("otf"),
    url("./assets/fonts/SpaceGrotesk-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("./assets/fonts/SpaceGrotesk-Light.woff2") format("woff2"),
    url("./assets/fonts/SpaceGrotesk-Light.otf") format("otf"),
    url("./assets/fonts/SpaceGrotesk-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("./assets/fonts/SpaceGrotesk-Medium.woff2") format("woff2"),
    url("./assets/fonts/SpaceGrotesk-Medium.otf") format("otf"),
    url("./assets/fonts/SpaceGrotesk-Medium.ttf") format("truetype");
  font-weight: medium;
  font-style: normal;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("./assets/fonts/SpaceGrotesk-Regular.woff2") format("woff2"),
    url("./assets/fonts/SpaceGrotesk-Regular.otf") format("otf"),
    url("./assets/fonts/SpaceGrotesk-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

button {
  padding: 20px 30px;
  border: none;
  font-family: "Space Grotesk";
  font-feature-settings: "ss04" on;
  font-weight: 500;
  font-style: normal;
  font-size: 13px;
  cursor: pointer;
  transition: background-color 0.2s ease-in;
}

button:focus {
  outline: none;
}

h1 {
  font-family: "Space Grotesk";
  font-feature-settings: "ss04" on;
  font-weight: 300;
  font-style: normal;
}

a {
  text-decoration: none;
  color: inherit;
}

div {
  font-family: "Space Grotesk";
  font-feature-settings: "ss04" on;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.crossFadeContainer {
  width: 100%;
}

.fade {
  opacity: 0;
  animation-name: fadeIn;
  animation-timing-function: ease-in;
  animation-duration: 0.7s;
  animation-fill-mode: forwards;
}

.did-fade {
  animation: fade-out 1s;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 30px;
  padding-left: 30px;
}

.active-slider {
  background: #15221e !important;
}

@media only screen and (max-width: 1500px) {
  .container {
    max-width: 1200px;
  }
}
@media only screen and (max-width: 1200px) {
  .container {
    max-width: 960px;
  }
}
@media only screen and (max-width: 900px) {
  .container {
    max-width: 720px;
  }
}
@media only screen and (min-width: 1500px) {
  .container {
    max-width: 1440px;
  }
}
